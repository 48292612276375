// action types
export const LOAD_LIST = "LOAD_LIST";
export const CREATE_BROADCAST = "CREATE_BROADCAST";
export const LOAD_COUNTRIES = "LOAD_COUNTRIES";
export const LOAD_USERS = "LOAD_USERS";
export const LOAD_OFFERS = "LOAD_OFFERS";

export const FILL_CACHE = "FILL_CACHE";
export const CREATE_FILE = "CREATE_FILE";
export const RETRY = "RETRY";

export const LOAD_DETAILS = "LOAD_DETAILS";
export const LOAD_TAGS = "LOAD_TAGS";

export const LOAD_COUNTRY_DETAILS = "LOAD_COUNTRY_DETAILS";

// mutation types
export const RECEIVE_LIST = "RECEIVE_LIST";
export const SET_ERROR = "SET_ERROR";
export const SET_STATUS = "SET_STATUS";
export const SET_DEFAULT_STATE = "SET_DEFAULT_STATE";
export const RECEIVE_COUNTRIES = "RECEIVE_COUNTRIES";
export const RECEIVE_USERS = "RECEIVE_USERS";
export const RECEIVE_OFFERS = "RECEIVE_OFFERS";
export const RECEIVE_DETAILS = "RECEIVE_DETAILS";
export const RECEIVE_TAGS = "RECEIVE_TAGS";
export const CHECK_AVAILABILITY_COUNTRIES = "CHECK_AVAILABILITY_COUNTRIES";
export const RECEIVE_COUNTRY_DETAILS = "RECEIVE_COUNTRY_DETAILS";