import * as TYPES from "./types";
import * as API from "./api";
import {requestStatus} from "@/services/services";

export default {
    [TYPES.LOGIN](context: any, credentials: any) {
        API.login(credentials)
            .then(({response, status, messages}: any) => {
                if (status === requestStatus.success) {
                    context.commit(TYPES.SET_AUTH, response.data);
                } else {
                    context.commit(TYPES.SET_ERROR, [messages]);
                }
            })
            .catch(({messages}: any) => {
                context.commit(TYPES.SET_ERROR, [messages]);
            });
    },
    [TYPES.LOGOUT](context: any) {
        context.commit(TYPES.PURGE_AUTH);
        if (context.state.isAuthenticated) {
            API.logout();
        }
    },

    [TYPES.VERIFY_AUTH](context: any) {
        const user = JSON.parse(localStorage.getItem("user") || 'null');
        if (user && user.token) {
            context.commit(TYPES.SET_AUTH, user);
        } else {
            context.commit(TYPES.PURGE_AUTH);
        }
    }
}