import Vue from "vue";
import Vuex from "vuex";
import camelCase from "lodash/camelCase";
import { ModuleTree } from 'vuex'

// Получаем все файлы
const requireModule = require.context(
  // Ищем файлы в текущей директории
  "./modules",
  // Ищем файлы в поддиректориях
  true,
  // Исключаем файл index.js, равно как и файлы, в именах которых
  // есть строки 'actions', 'mutations', или 'getters' .
  // Кроме того, включаем только файлы с расширением .js
  /^(?!.*(actions|mutations|getters|index|types|api|interfaces)).*\.ts$/
);

const modules: ModuleTree<unknown> = {
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
};

requireModule.keys().forEach(fileName => {
  // Игнорируем файлы модульных тестов
  if (/\.unit\.js$/.test(fileName)) return;
  // Избавляемся от расширений файлов для преобразования их имён к верблюжьему стилю
  const key : 'state' | 'mutations' | 'actions' | 'modules' = camelCase(fileName.split("/")[1].replace(/(\.\/|\.js)/g, ""));
  modules[key] = {
    namespaced: true,
    ...requireModule(fileName).default
  };
});

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== "production"
});

// Автоматически запустить действие `init` для каждого существующего модуля
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions?.init) {
    store.dispatch(`${moduleName}/init`);
  }
}

export default store;