import * as TYPES from "./types";
// import {rules} from "@/services/rules";

const default_state = {
    list: [],
    count: 0,
    loading: false,
    needUpdate: false,
    rules: null,
    elastic_rules: null,
    filter: '',
    status: { change_field: null },
    details: {},
    rulePathes: [],
};

export default {
    [TYPES.SET_ERROR](state: any, error: any) {
        state.errors = error;
    },
    [TYPES.RECEIVE_LIST](state: any, data: any) {
        state.list = data.results;
        state.count = data.count;
    },
    [TYPES.SET_LOADING](state: any, value: boolean) {
        state.loading = value;
    },
    [TYPES.INIT_RULES](state: any, value: string) {
        // state.rules = rules;
        state.rules = value;
    },
    [TYPES.INIT_ELASTIC_RULES](state: any, value: string) {
        // state.rules = rules;
        state.elastic_rules = value;
    },
    [TYPES.SET_FILTER](state: any, value: string) {
        state.filter = value;
        state.needUpdate = true;
    },
    [TYPES.SET_NEED_UPDATE](state: any, value: boolean) {
        state.needUpdate = value;
    },
    [TYPES.RECEIVE_DETAILS](state: any, value: any) {
        state.details = value;
    },
    [TYPES.SET_STATUS](state: any, payload: any) {
        state.status = {
            ...state.status,
            [payload.field]: payload.status,
            messages: payload.messages,
            change_field: payload.field
        };
    },
    [TYPES.SET_DEFAULT_STATE](state: any) {
        state.list = default_state.list;
        state.count = default_state.count;
        state.loading = default_state.loading;
        state.needUpdate = default_state.needUpdate;
        state.rules = default_state.rules;
        state.status = default_state.status;
        state.details = default_state.details;
    },
    [TYPES.RECEIVE_TEMPLATES](state: any, data: any) {
        state.filterTemplates = data.results;
    },
    [TYPES.RECEIVE_TEMPLATES_v2](state: any, data: any) {
        state.filterTemplates_v2 = data.results;
    },
    [TYPES.RECEIVE_PATHES](state: any, data: any) {
        state.rulePathes = data;
    },
};
