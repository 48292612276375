import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
export * from "./types";

const state = {
    filter: {
        limit: 25,
        offset: 0
    },
    default_filter: true
};

export default {
    state,
    actions,
    mutations,
    getters
};
