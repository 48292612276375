import actions from "./actions";
import mutations from "./mutations";
import {ILayoutState} from "@/store/modules/layout/interfaces";
// import getters from "./getters";
export * from "./types";

const state: ILayoutState = {
    header: {
        pageName: '',
        actions: []
    }
};

export default {
    state,
    actions,
    mutations,
    // getters
};
