import Http from "@/services/api.service";
import { service_url } from "@/services/config";

export function loadList(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts/?${filter ? filter : ''}`,
        method: "get"
    });
}

export function loadList_v2(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v2/broadcasts/?${filter ? filter : ''}`,
        method: "get"
    });
}

export function loadBroadcastGroups(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts_groups/?${filter ? filter : ''}`,
        method: "get"
    });
}

export function loadDetails(id: number) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts/${id}/`,
        method: "get"
    });
}

export function createBroadcast(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts/create/`,
        method: "post",
        data
    });
}
export function createBroadcast_v2(data: any) {
    return Http._axios({
        url: `${service_url}/api/v2/broadcasts/create/`,
        method: "post",
        data
    });
}

export function exportBroadcast(data: any) {
    return Http._axios({
        url: `${service_url}/api/v2/export/`,
        method: "post",
        data
    });
}

export function getExportBroadcast(filter: any) {
    return Http._axios({
        url: `${service_url}/api/v2/export/?${filter || ''}`,
        method: "get"
    });
}


export function loadCountries(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/countries/?${filter || ''}`,
        method: "get"
    });
}

export function loadCountries_v2(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v2/countries/?${filter || ''}`,
        method: "get"
    });
}

export function loadUsers(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/users/?${filter || ''}`,
        method: "get"
    });
}
export function onLoadUserInfo(id?: any) {
    return Http._axios({
        url: `${service_url}/api/v1/users/${id}`,
        method: "get"
    });
}

export function loadCountriesDetails(id: string) {
    return Http._axios({
        url: `${service_url}/api/v1/countries/${id || ''}/`,
        method: "get"
    });
}

export function loadOffers(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/offers/?${filter || ''}`,
        method: "get"
    });
}

export function loadOffers_v2(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v2/offers/?${filter || ''}`,
        method: "get"
    });
}

export function loadTags(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/tags/?${filter || ''}`,
        method: "get"
    });
}

export function fillCache(id: number) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts/${id}/fill_cache/`,
        method: "post"
    });
}

export function fillCache_v2(id: number) {
    return Http._axios({
        url: `${service_url}/api/v2/broadcasts/${id}/fill_cache/`,
        method: "post"
    });
}

export function createFile(id: number) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts/${id}/create_file/`,
        method: "post"
    });
}

export function retry(id: number) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts/${id}/retry/`,
        method: "post"
    });
}

export function updateBroadcast(id: any, data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts/${id}/update/`,
        method: "patch",
        data
    });
}
export function updateBroadcast_v2(id: any, data: any) {
    return Http._axios({
        url: `${service_url}/api/v2/broadcasts/${id}/update/`,
        method: "patch",
        data
    });
}

export function loadDataForElasticRequest(url?: string, filter?: string) {
    return Http._axios({
        url: `${url}?${filter || ''}`,
        method: "get"
    });
}

export function retryBroadcast_v2(id: number) {
    return Http._axios({
        url: `${service_url}/api/v2/broadcasts/${id}/retry/`,
        method: "post"
    });
}