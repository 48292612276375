import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/store'
import vuetify from './plugins/vuetify'
import Toaster from "v-toaster";
import "v-toaster/dist/v-toaster.css";
import moment from 'moment'

window.moment = moment;
import * as Sentry from "@sentry/vue";


if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://af79e4e119a978404e45b466fa8dd63b@sentry.holyaff.com/14",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}



Vue.config.productionTip = false
Vue.use(Toaster, { timeout: 5000 });


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
