import * as TYPES from "./types";
import * as API from "./api";
import { requestStatus } from "@/services/services";
import { updateTemplate } from "./api";
import { UPDATE_TEMPLATE, LOAD_TEMPLATES_v2 } from "./types";

interface IResponse {
    response: any;
    status: any;
}

export default {
    [TYPES.LOAD_LIST]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_list";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadList(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_LIST, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load contacts error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_NEED_UPDATE, false);
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.LOAD_DETAILS]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_details";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadDetails(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE_DETAILS, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load details error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.IMPORT_CSV]({ commit }: any, data: any) {
        let status = requestStatus.loading;
        const field = "upload_csv";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });
        API.importCSV(data)
            .then(({ status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Upload file error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.LOAD_RULES]({ commit }: any) {
        let status = requestStatus.loading;
        const field = "load_rules";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });
        API.loadRules()
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (status === requestStatus.success) {
                    commit(TYPES.INIT_RULES, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load rules error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    async [TYPES.LOAD_ELASTIC_RULES]({ commit }: any) {
        let status = requestStatus.loading;
        const field = "load_elastic_rules";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });
        await API.loadElasticRules()
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (status === requestStatus.success) {
                    commit(TYPES.INIT_ELASTIC_RULES, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load rules error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    async [TYPES.SET_ELASTIC_RULES]({ commit }: any, data: any) {
        await commit(TYPES.INIT_ELASTIC_RULES, data);
    },
    [TYPES.CREATE_TEMPLATE]({ commit }: any, data: any) {
        let status = requestStatus.loading;
        const field = "create_template";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.createTemplate(data)
            .then(({ status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Create template error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.UPDATE_TEMPLATE]({ commit }: any, data: any) {
        let status = requestStatus.loading;
        const field = "update_template";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.updateTemplate(data.data, data.id)
            .then(({ status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Update template error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.LOAD_TEMPLATES]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_templates";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadTemplates(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_TEMPLATES, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load templates error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.LOAD_TEMPLATES_v2]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_templates";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadTemplates_v2(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_TEMPLATES_v2, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load templates error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.LOAD_PATCHES]({ commit }: any) {
        let status = requestStatus.loading;
        const field = "load_pathes";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadElasticPathes()
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_PATHES, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load templates error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
}
