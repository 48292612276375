import * as TYPES from "./types";
import * as API from "./api";
import {requestStatus} from "@/services/services";

export default {
    [TYPES.LOAD_LIST]({commit}: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_domains";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.loadList(data)
            .then(({response, status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_LIST, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load domains error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.CREATE_DOMAINS]({commit}: any, data: any) {
        let status = requestStatus.loading;
        const field = "create_domains";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.createDomains(data)
            .then(({status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Create domain error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.EDIT_DOMAINS]({commit}: any, data: any) {
        let status = requestStatus.loading;
        const field = "edit_domains";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.editDomains(data.data, data.id)
            .then(({status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Edit domain error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    }
}