import * as TYPES from "./types";

export default {
    [TYPES.SET_ERROR](state: any, error: any) {
        state.errors = error;
    },
    [TYPES.SET_AUTH](state: any, payload: any) {
        state.isAuthenticated = true;
        localStorage.setItem("user", JSON.stringify(payload));
    },
    [TYPES.PURGE_AUTH](state: any) {
        state.isAuthenticated = false;
        localStorage.removeItem("user");
    }
};
