import * as TYPES from "./types";
import * as API from "./api";
import {requestStatus} from "@/services/services";
import {loadCountriesDetails} from "./api";

export default {
    [TYPES.LOAD_LIST]({commit}: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_list";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.loadList(data)
            .then(({response, status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_LIST, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load broadcasts error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.LOAD_DETAILS]({commit}: any, data: number) {
        let status = requestStatus.loading;
        const field = "load_details";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.loadDetails(data)
            .then(({response, status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE_DETAILS, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load details error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.LOAD_COUNTRY_DETAILS]({commit}: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_country_details";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.loadCountriesDetails(data)
            .then(({response, status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE_COUNTRY_DETAILS, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load country error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.LOAD_COUNTRIES]({commit}: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_countries";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.loadCountries(data)
            .then(({response, status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_COUNTRIES, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load countries error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.LOAD_USERS]({commit}: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_users";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.loadUsers(data)
            .then(({response, status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_USERS, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load countries error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.LOAD_OFFERS]({commit}: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_offers";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.loadOffers(data)
            .then(({response, status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE_OFFERS, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load offers error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.LOAD_TAGS]({commit}: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_tags";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.loadTags(data)
            .then(({response, status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE_TAGS, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load tags error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.CREATE_BROADCAST]({commit}: any, data: any) {
        let status = requestStatus.loading;
        const field = "create";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.createBroadcast(data)
            .then(({status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Create broadcast error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.FILL_CACHE]({commit}: any, id: number) {
        let status = requestStatus.loading;
        const field = "fill_cache";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field});

        API.fillCache(id)
            .then(({status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Fill cache error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.CREATE_FILE]({commit}: any, id: number) {
        let status = requestStatus.loading;
        const field = "create_file";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field, id});

        API.createFile(id)
            .then(({status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Create file error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.RETRY]({commit}: any, id: number) {
        let status = requestStatus.loading;
        const field = "retry";
        let messages = '';
        commit(TYPES.SET_STATUS, {status, field, id});

        API.retry(id)
            .then(({status: statusField, messages: messagesField}: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Retry error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    }
}