// action types
export const LOAD_LIST = "LOAD_LIST";
export const LOAD_DETAILS = "LOAD_DETAILS";
export const LOAD_RULES = "LOAD_RULES";
export const LOAD_ELASTIC_RULES = "LOAD_ELASTIC_RULES";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const LOAD_TEMPLATES = "LOAD_TEMPLATES";
export const LOAD_TEMPLATES_v2 = "LOAD_TEMPLATES_v2";
export const IMPORT_CSV = "IMPORT_CSV";
export const LOAD_PATCHES = "LOAD_PATCHES";
export const SET_ELASTIC_RULES = "SET_ELASTIC_RULES";
// mutation types
export const RECEIVE_LIST = "RECEIVE_LIST";
export const RECEIVE_DETAILS = "RECEIVE_DETAILS";
export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const INIT_RULES = "INIT_RULES";
export const INIT_ELASTIC_RULES = "INIT_ELASTIC_RULES";
export const SET_FILTER = "SET_RULES";
export const SET_STATUS = "SET_STATUS";
export const SET_DEFAULT_STATE = "SET_DEFAULT_STATE";
export const SET_NEED_UPDATE = "SET_NEED_UPDATE";
export const RECEIVE_TEMPLATES = "RECEIVE_TEMPLATES";
export const RECEIVE_TEMPLATES_v2 = "RECEIVE_TEMPLATES_v2";
export const RECEIVE_PATHES = "RECEIVE_PATHES";
