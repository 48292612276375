import Http from "@/services/api.service";
import { service_url } from "@/services/config";

export function loadList(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v1/domains/?${filter || ''}`,
        method: "get"
    });
}

export function createDomains(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/domains/create/`,
        method: "post",
        data
    });
}

export function editDomains(data: any, id: any) {
    return Http._axios({
        url: `${service_url}/api/v1/domains/${id}/update/`,
        method: "patch",
        data
    });
}
