import moment from "moment";
// import * as BroadcastsAPI from "@/store/modules/broadcasts/api";


export const SELECTIZE_SETTINGS = {
    "countries": {
        valueField: "code",
    },
    "broadcasts.uuid": {
        valueField: "uuid",
    },
    broadcasts_$_has_clicks: {
        valueField: "uuid",

    },
    broadcasts_$_has_no_clicks: {
        valueField: "uuid",

    },
    broadcasts_$_has_leads: {
        valueField: "uuid",

    },
    "broadcasts.offer_$_has_no_leads": {
        valueField: "uuid",

    },
    "sources_offers": {
        valueField: "uuid",
    },
    "sources.offer.uuid": {
        valueField: "uuid",
    },
    "sources.offer_$_has_leads": {
        valueField: "uuid",

    },
    broadcasts_$_in_broadcast_groups: {
        valueField: "id",

    },
}

export const requestStatus = {
    loading: "loading",
    success: "success",
    error: "error",
    error_connection: "error_connection",
    logout: "logout"
};

export const BROADCASTS_STATUS = {
    WAITING: 0,
    PROCESSING: 10,
    ERROR: 20,
    SUCCESS: 50
}
export const IMPORED_FILE_STATUS = {
    0: 'CREATED',
    1: 'PROCESSING',
    2: 'SUCCESS',
    3: 'ERROR'
}

export function getImportedFileStatusColor(status: number) {
    let color = "";
    switch (status) {
        case 0:
            color = "warning";
            break;
        case 1:
            color = "info";
            break;
        case 3:
            color = "danger";
            break;
        case 2:
            color = "success";
            break;
        default:
            color = "default";
    }
    return color;
}

export function getColorBroadcastStatus(status: number) {
    let color = "";
    switch (status) {
        case BROADCASTS_STATUS.WAITING:
            color = "warning";
            break;
        case BROADCASTS_STATUS.PROCESSING:
            color = "info";
            break;
        case BROADCASTS_STATUS.ERROR:
            color = "danger";
            break;
        case BROADCASTS_STATUS.SUCCESS:
            color = "success";
            break;
        default:
            color = "default";
    }
    return color;
}


export function getTitleBroadcastStatus(status: number) {
    let title = "";
    switch (status) {
        case BROADCASTS_STATUS.WAITING:
            title = "WAITING";
            break;
        case BROADCASTS_STATUS.PROCESSING:
            title = "PROCESSING";
            break;
        case BROADCASTS_STATUS.ERROR:
            title = "ERROR";
            break;
        case BROADCASTS_STATUS.SUCCESS:
            title = "SUCCESS";
            break;
    }
    return title;
}

// export const DATE_FORMAT = "DD.MM.YYYY";
// export const renderDate = (date: Date) => {
//     return moment(date).format(DATE_FORMAT);
// };

// export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm:ss";
// export const renderDateTime = (date: Date) => {
//     return moment.parseZone(date).format(DATE_TIME_FORMAT);
// };

export const renderDateTime = (date: Date) => {
    // const formatter = new Intl.DateTimeFormat("default", {
    //     hour: "numeric",
    //     minute: "numeric",
    //     second: "numeric",
    //     year: "numeric",
    //     month: "numeric",
    //     day: "numeric",
    //     timeZone: 'UTC'
    // });
    // return formatter.format(new Date(date));
    return moment.utc(date).format("DD.MM.YYYY HH:mm:ss");
};
export const renderDate = (date: Date) => {
    // const formatter = new Intl.DateTimeFormat("default", {
    //     hour: "numeric",
    //     minute: "numeric",
    //     second: "numeric",
    //     year: "numeric",
    //     month: "numeric",
    //     day: "numeric",
    //     timeZone: 'UTC'
    // });
    // return formatter.format(new Date(date));
    return moment.utc(date).format("DD.MM.YYYY HH:mm:ss");
};
export const renderDateShort = (date: Date) => {
    // const formatter = new Intl.DateTimeFormat("default", {
    //     year: "numeric",
    //     month: "numeric",
    //     day: "numeric",
    //     timeZone: 'UTC'
    // });
    // return formatter.format(new Date(date));
    return moment.utc(date).format("DD.MM.YYYY");
};

export function buildFilterString(query: { [key: string]: string | number | boolean }) {
    let result = "";
    for (const key in query) {
        result +=
            query[key] !== undefined
                ? `&${key}=${query[key]}`
                : "";
    }
    return result.substring(1, result.length);
}

export const isEmptyObject = (obj: { [key: string]: string | number | boolean }) => {
    return Object.keys(obj).length === 0;
}

export const toBooleanValue = (value: string | boolean) => {
    return (value + '' === 'true' || value + '' === 'false') ? Boolean(value) : ''
};

export const BROADCAST_TARGETS = [
    {
        name: 'sms',
        value: 'sms'
    },
    {
        name: 'email',
        value: 'email'
    },
    {
        name: 'watsapp',
        value: 'wa'
    }
];

export function changeUrlQuery(query: any) {
    const currentUrl = `${window.location.origin}${window.location.pathname}`;
    const newUrl = `${currentUrl}?${buildFilterString(query)}`;
    history.replaceState(null, "", newUrl);
}

export function objectFromQueryString() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const paramsObject: Record<string, any> = {};
    params.forEach((value, key) => {

        paramsObject[key] = value;
    });
    return paramsObject;
}

export function isObjectEmpty(obj: any) {
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }
    return true;
}