import * as TYPES from "./types";

const default_state = {
    count: 0,
    list: [],
    status: {change_field: null}
};

export default {
    [TYPES.SET_ERROR](state: any, error: any) {
        state.errors = error;
    },
    [TYPES.RECEIVE_LIST](state: any, data: any) {
        state.list = data.results;
        state.count = data.count;
    },
    [TYPES.SET_STATUS](state: any, payload: any) {
        state.status = {
            ...state.status,
            [payload.field]: payload.status,
            messages: payload.messages,
            change_field: payload.field,
            editing_domain: payload.editing_domain
        };
    },
    [TYPES.SET_DEFAULT_STATE](state: any) {
        state.list = default_state.list;
        state.count = default_state.count;
        state.status = default_state.status;
    }
};
