import Http from "@/services/api.service";
import { service_url } from "@/services/config";

export function login(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/auth/login/`,
        method: "post",
        data: { ...data }
    });
}
export function logout() {
    return Http._axios({
        url: `${service_url}/api/v1/auth/logout/`,
        method: "post"
    });
}
