import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
export * from "./types";

const state = {
    list: [],
    count: 0,
    status: { change_field: null }
};

export default {
    state,
    actions,
    mutations,
    getters
};
