<template>
  <v-app>
    <router-view />
    <tooltip-component />
    <ConfirmModal ref="global_confirm_modal" />
  </v-app>
</template>

<script>
import Vue from "vue";
import TooltipComponent from "./components/TooltipComponent";
import ConfirmModal from "./components/ConfirmModal";

export default Vue.extend({
  name: "App",
  components: { TooltipComponent, ConfirmModal },
  data: () => ({}),
  created() {
    window.getApp = this;
  },
});
</script>

<style lang="scss">
@import "./assets/styles";
@import "./assets/builder";
</style>
