import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: "/",
        redirect: "/contacts",
        component: () => import('@/scenes/AuthPage.vue'),
        children: [
            {
                path: '/contacts-old',
                name: 'contacts-old',
                component: () => import('@/containers/Contacts/Contacts.vue'),
            },
            {
                path: '/contacts',
                name: 'contacts',
                component: () => import('@/containers/ElasticContacts/Contacts.vue'),
            },
            {
                path: '/contacts/:id',
                name: 'contactDetails',
                component: () => import('@/containers/Contacts/Contact.vue')
            },
            {
                path: '/broadcasts-old',
                name: 'broadcasts-old',
                component: () => import('@/containers/Broadcasts/Broadcasts.vue')
            },
            {
                path: '/broadcasts',
                name: 'broadcasts',
                component: () => import('@/containers/Broadcasts_v2/Broadcasts_v2.vue')
            },
            {
                path: '/domains',
                name: 'domains',
                component: () => import('@/containers/Domains/Domains.vue')
            },
            {
                path: '/imported-files',
                name: 'imported-files',
                component: () => import('@/containers/ImportedFiles/ImportedFiles.vue')
            },
            {
                path: '/exported-files',
                name: 'exported-files',
                component: () => import('@/containers/ExportedBroadcasts/ExportedBroadcasts.vue')
            },
            {
                path: '/broadcasts-groups',
                name: 'broadcasts-groups',
                component: () => import('@/containers/Groups/Groups.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/scenes/Login.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
