import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
export * from "./types";

const state = {
    count: 0,
    list: [],
    status: { change_field: null },
    countries: [],
    countryDetails: '',
    offers: [],
    details: {},
    tags: [],
    users: []
};

export default {
    state,
    actions,
    mutations,
    getters
};
