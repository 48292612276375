import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
export * from "./types";

const state = {
    errors: [],
    user: {},
    list: [],
    count: 0,
    loading: false,
    needUpdate: false,
    rules: null,
    elastic_rules: null,
    filter:  JSON.parse(localStorage.getItem('filter_conditions') || 'null') || '',
    filterTemplates: [],
    filterTemplates_v2: [],
    rulePathes: [],
    status: { change_field: null }
};
export default {
    state,
    actions,
    mutations,
    getters
};
