import * as TYPES from "./types";

const default_state = {
    count: 0,
    list: [],
    countries: [],
    users: [],
    offers: [],
    status: {change_field: null},
    details: {},
    tags: []
};

export default {
    [TYPES.SET_ERROR](state: any, error: any) {
        state.errors = error;
    },
    [TYPES.RECEIVE_LIST](state: any, data: any) {
        state.list = data.results;
        state.count = data.count;
    },
    [TYPES.RECEIVE_DETAILS](state: any, data: any) {
        state.details = {...data};
    },
    [TYPES.RECEIVE_COUNTRIES](state: any, data: any) {
        state.countries = data.results;
    },
    [TYPES.RECEIVE_USERS](state: any, data: any) {
        state.users = data.results;
    },
    [TYPES.RECEIVE_OFFERS](state: any, data: any) {
        state.offers = [...data.results];
    },
    [TYPES.RECEIVE_TAGS](state: any, data: any) {
        state.tags = [...data.results];
    },
    [TYPES.RECEIVE_DETAILS](state: any, data: any) {
        state.details = {...data};
    },
    [TYPES.RECEIVE_COUNTRY_DETAILS](state: any, data: any) {
        state.countryDetails = {...data};
    },
    [TYPES.SET_STATUS](state: any, payload: any) {
        state.status = {
            ...state.status,
            [payload.field]: payload.status,
            messages: payload.messages,
            change_field: payload.field,
            id: payload.id
        };
    },
    [TYPES.SET_DEFAULT_STATE](state: any) {
        state.list = default_state.list;
        state.countries = default_state.countries;
        state.users = default_state.users;
        state.offers = default_state.offers;
        state.count = default_state.count;
        state.status = default_state.status;
        state.details = default_state.details;
        state.tags = default_state.tags;
    }
};
